<template>
  <div>
    <location />
    <div id="brs" class="page wrap-1200">
      <div class="filter-group row g-0">
        <div class="col-sm-auto mb-2 mb-sm-0">
          <b-form @submit="onSubmit">
            <div class="d-flex">
              <select
                class="w-auto me-2"
                name="searchtype"
                v-model="searchData.searchtype"
              >
                <option value="G.fit_goods_nm">
                  {{ $t("filter.membership.goods_name") }}
                </option>
                <option value="B.fit_brand_nm">
                  {{ $t("filter.common.brand_name") }}
                </option>
                <option value="O.nickname">
                  {{ $t("filter.common.location_name") }}
                </option>
              </select>
              <div class="input-group flex-nowrap">
                <input
                  type="text"
                  class="form-control text-truncate"
                  :placeholder="$t('text.common.enter_search_term')"
                  aria-describedby="button-addon"
                  name="searchname"
                  v-model="searchData.searchname"
                />
                <button
                  class="btn btn-primary btn-search"
                  type="submit"
                  id="button-addon"
                ></button>
              </div>
            </div>
          </b-form>
        </div>

        <div class="col-sm-auto ms-sm-auto">
          <select class="" v-model="searchData.findex">
            <option value="F.reg_date">
              {{ $t("filter.membership.by_application_date") }}
            </option>
            <option value="B.fit_brand_nm">
              {{ $t("filter.membership.by_brand") }}
            </option>
            <option value="GC.fit_club_nm">
              {{ $t("filter.membership.by_club") }}
            </option>
            <option value="O.nickname">
              {{ $t("filter.membership.by_shop") }}
            </option>
          </select>
        </div>
      </div>

      <table class="table board toggle-xl">
        <thead>
          <tr>
            <th class="w-1">
              <input
                type="checkbox"
                v-model="allSelected"
                @click="selectAll()"
                class="m-0"
              />
            </th>
            <th>
              {{ $t("title.membership.goods_name") }}
            </th>
            <th class="d-none d-md-table-cell">
              {{ $t("title.membership.club_info") }}
            </th>
            <th class="d-none d-xl-table-cell">
              {{ $t("title.common.location") }}
            </th>
            <th class="d-none d-xl-table-cell">
              {{ $t("title.membership.application_date") }}
            </th>
            <th class="w-1">
              {{ $t("title.membership.function") }}
            </th>
            <th class="th-arrow">&nbsp;</th>
          </tr>
        </thead>
        <tbody v-if="total_cnt > 0">
          <template v-for="(item, index) in items">
            <template>
              <tr :key="index">
                <!-- 
              <td class="col-auto d-lg-table-cell d-none">
                <img :src="item.fit_brand_img | get_img" />
              </td>
                -->
                <td class="d-lg-table-cell w-1">
                  <input
                    type="checkbox"
                    class="form-check-input custom-checkbox m-0"
                    :value="item.fittingno"
                    v-model="checkedFitidx"
                    @change="updateCheckall()"
                  />
                </td>
                <td class="d-lg-table-cell text-start">
                  <!-- <span class="badge bg-secondary bg-opacity-50">피팅대기</span>
                <span class="badge bg-orange">피팅완료</span>
                <span class="badge bg-secondary bg-opacity-50">제품준비</span>
                <span class="badge bg-orange">배송중</span> -->
                  <span class="badge" :class="get_status_bg(item)">{{
                    get_status_val(item)
                  }}</span
                  ><br />
                  <router-link
                    :to="{
                      name: 'FittingSwingList',
                      params: {
                        fittingno: item.fittingno,
                      },
                    }"
                    class="d-inline-block my-2"
                  >
                    <span class="d-inline-block me-1 brand-name fs-14">{{
                      item.fit_brand_nm
                    }}</span>
                    <span class="d-inline-block goods-kind">
                      {{ item.goodstype | get_club_type }}({{
                        item.clubtype_code_name
                      }})
                    </span>
                    <span class="d-block goods-name">{{ item.goodsname }}</span>
                  </router-link>
                  <div class="attached-state ms-n1px fs-13">
                    <span
                      class="d-inline-flex align-items-center me-1"
                      v-if="item.is_video"
                      ><i class="video-state has-video"></i
                      >{{ $t("title.membership.video") }}</span
                    >

                    <span
                      class="d-inline-flex align-items-center"
                      v-if="item.req_info.fit_ans_file"
                      ><i class="has-attachment"></i
                      >{{ $t("title.common.attachment") }}</span
                    >
                  </div>
                </td>
                <td class="d-none d-md-table-cell text-start">
                  <router-link
                    :to="{
                      name: 'FittingSwingList',
                      params: {
                        fittingno: item.fittingno,
                      },
                    }"
                    class="d-inline-block"
                  >
                    {{ $t("title.common.shaft") }}({{ item.shaftname }} /
                    {{ item.shaftmaterial | get_shaft_material }}
                    /
                    {{ item.shaftflextype | get_balance }})<br />
                    {{ $t("title.common.loft") }} ({{ item.goodsloft || "-" }})
                    {{ $t("title.common.lie") }} ({{ item.goodslie || "-" }})
                    {{ $t("title.common.sole") }} ({{ item.goodssole || "-" }})
                    FP ({{ item.goodsfp || "-" }})<br />{{
                      $t("title.common.length")
                    }}
                    ({{ item.goodslength || "-" }})
                    {{ $t("title.common.total_weight") }} ({{
                      item.goodsweight || "-"
                    }})
                  </router-link>
                </td>
                <td class="d-none d-xl-table-cell shop-name">
                  {{ item.shopname }}
                </td>
                <td class="d-none d-xl-table-cell text-date">
                  {{ $dayjs(item.regdate).format("YYYY-MM-DD") }}
                </td>
                <td class="d-lg-table-cell btn-group-vertical">
                  <button
                    class="btn btn-outline-primary btn-xs"
                    v-if="
                      item.req_info.fit_req_pk && item.req_info.state_cd == '0'
                    "
                    @click="fittingCancel(item.req_info.fit_req_pk)"
                  >
                    {{ $t("button.membership.cancel_fitting") }}
                  </button>
                  <button
                    class="btn btn-outline-primary btn-xs"
                    v-if="
                      item.req_info.fit_req_pk && item.req_info.state_cd > 0
                    "
                    @click="
                      $router.push({
                        name: 'FittingAnswerView',
                        params: {
                          fitreqno: item.req_info.fit_req_pk,
                        },
                        query: { goodstype: $route.query.goodstype },
                      })
                    "
                  >
                    {{ $t("button.membership.view_fitting") }}
                  </button>
                  <button
                    class="btn btn-outline-primary btn-xs"
                    v-if="
                      item.req_info.fit_req_pk && item.req_info.state_cd > 1
                    "
                    @click="
                      $router.push({
                        name: 'FittingSaleView',
                        params: {
                          fitansno: item.req_info.fit_ans_fk,
                        },
                        query: { goodstype: $route.query.goodstype },
                      })
                    "
                  >
                    {{ $t("button.membership.purchased_goods") }}
                  </button>
                  <button
                    class="btn btn-outline-primary btn-xs"
                    v-if="
                      item.req_info.fit_req_pk &&
                      item.req_info.fit_ans_fk != '0' &&
                      item.req_info.state_cd < 2
                    "
                    @click="buyCancel(item.req_info.fit_req_pk)"
                  >
                    {{ $t("button.membership.cancel_purchase") }}
                  </button>
                  <button
                    class="btn btn-outline-primary btn-xs"
                    v-if="
                      item.req_info.fit_req_pk && item.req_info.state_cd > 1
                    "
                    @click="
                      onClickRedirect(
                        item.req_info.ship_url + item.req_info.ship_invoice_no
                      )
                    "
                  >
                    {{ $t("button.membership.view_delivery") }}
                  </button>

                  <b-dropdown
                    v-if="item.fit_group_no > 0"
                    id="dropdown-right"
                    no-caret
                    no-flip
                    right
                    text="그룹관리"
                    variant="outline-primary"
                    size="xs"
                  >
                    <b-dropdown-item
                      @click="groupFlag.push(item.fit_group_no)"
                      v-if="groupFlag.indexOf(item.fit_group_no) == -1"
                      >{{ $t("button.membership.open_group") }}</b-dropdown-item
                    >
                    <b-dropdown-item
                      @click="
                        groupFlag = groupFlag.filter(
                          (v) => v != item.fit_group_no
                        )
                      "
                      v-if="groupFlag.indexOf(item.fit_group_no) > -1"
                      >{{
                        $t("button.membership.close_group")
                      }}</b-dropdown-item
                    >
                    <b-dropdown-item @click="groupDrop(item.fit_group_no)">{{
                      $t("button.membership.ungroup")
                    }}</b-dropdown-item>
                  </b-dropdown>
                </td>
                <td class="td-arrow" @click="toggle_tr(index)">
                  <img
                    src="/img/arrow_none_collapsed.png"
                    :class="{ 'btn-toggle-arrow': view_hide === index }"
                  />
                </td>
              </tr>
              <tr
                class="d-xl-none"
                :class="{ show: view_hide === index }"
                :key="index + 'r'"
                v-if="view_hide === index"
              >
                <td colspan="6">
                  <div class="toggle-content">
                    <div class="row d-md-none">
                      <div class="col-3">
                        {{ $t("title.membership.club_info") }}
                      </div>
                      <div class="col-9">
                        <router-link
                          :to="{
                            name: 'FittingSwingList',
                            params: {
                              fittingno: item.fittingno,
                            },
                          }"
                          class="d-inline-block"
                        >
                          {{ $t("title.common.shaft") }}({{ item.shaftname }}
                          /
                          {{ item.shaftmaterial | get_shaft_material }}
                          /
                          {{ item.shaftflextype | get_balance }})<br />
                          {{ $t("title.common.loft") }} ({{
                            item.goodsloft || "-"
                          }}) {{ $t("title.common.lie") }} ({{
                            item.goodslie || "-"
                          }}) {{ $t("title.common.sole") }} ({{
                            item.goodssole || "-"
                          }}) FP ({{ item.goodsfp || "-" }})
                          {{ $t("title.common.length") }} ({{
                            item.goodslength || "-"
                          }}) {{ $t("title.common.total_weight") }} ({{
                            item.goodsweight || "-"
                          }})
                        </router-link>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-3">{{ $t("title.common.location") }}</div>
                      <div class="col-9 shop-name">{{ item.shopname }}</div>
                    </div>
                    <div class="row">
                      <div class="col-3">
                        {{ $t("title.membership.application_date") }}
                      </div>
                      <div class="col-9 text-date">
                        {{ $dayjs(item.regdate).format("YYYY-MM-DD") }}
                      </div>
                    </div>
                  </div>
                  <!-- //toggle-content -->
                </td>
              </tr>
            </template>

            <template
              v-if="item.grouplist && groupFlag.indexOf(item.fit_group_no) > -1"
            >
              <template
                v-for="(item_, index_) in item.grouplist.filter(
                  (v) => v.fit_pk != item.fit_pk
                )"
              >
                <tr :key="'a' + index_">
                  <td class="d-lg-table-cell w-1"></td>
                  <td class="d-lg-table-cell ps-0 text-start">
                    <span class="badge" :class="get_status_bg(item)">{{
                      get_status_val(item)
                    }}</span>
                    <router-link
                      :to="{
                        name: 'FittingSwingList',
                        params: {
                          fittingno: item.fittingno,
                        },
                      }"
                    >
                      <p class="my-2">
                        <span
                          class="d-inline-block me-1 brand-name fs-14"
                        ></span>
                        <span class="d-inline-block goods-kind">
                          {{ item_.goodstype | get_club_type }}({{
                            item_.clubtype_code_name
                          }})
                        </span>
                        <span class="d-block goods-name">{{
                          item_.goodsname
                        }}</span>
                      </p>
                    </router-link>
                    <div class="attached-state ms-n1px fs-13">
                      {{ item.fit_group_no }}
                      <span
                        class="d-inline-flex align-items-center me-2"
                        v-if="item.is_video"
                        ><i class="video-state has-video"></i
                        >{{ $t("title.membership.video") }}</span
                      >
                      <span
                        class="d-inline-flex align-items-center"
                        v-if="item.req_info.fit_ans_file"
                        ><a :href="item.req_info.fit_ans_file | get_download"
                          ><i class="has-attachment"></i
                          >{{ $t("title.common.attachment") }}</a
                        ></span
                      >
                    </div>
                  </td>
                  <td class="d-none d-lg-table-cell text-start">
                    <router-link
                      :to="{
                        name: 'FittingSwingList',
                        params: {
                          fittingno: item.fittingno,
                        },
                      }"
                    >
                      {{ $t("title.common.shaft") }}({{ item_.shaftname }} /
                      {{ item_.shaftmaterial | get_shaft_material }}
                      /
                      {{ item_.shaftflextype | get_balance }})<br />
                      {{ $t("title.common.loft") }} ({{
                        item_.goodsloft || "-"
                      }}) {{ $t("title.common.lie") }} ({{
                        item_.goodslie || "-"
                      }}) {{ $t("title.common.sole") }} ({{
                        item_.goodssole || "-"
                      }}) FP ({{ item_.goodsfp || "-" }})<br />{{
                        $t("title.common.length")
                      }}
                      ({{ item_.goodslength || "-" }})
                      {{ $t("title.common.total_weight") }} ({{
                        item_.goodsweight || "-"
                      }})
                    </router-link>
                  </td>
                  <td class="d-none d-lg-table-cell shop-name">
                    {{ item_.shopname }}
                  </td>
                  <td class="d-none d-lg-table-cell text-date">
                    {{ $dayjs(item_.regdate).format("YYYY-MM-DD") }}
                  </td>
                  <td class="d-lg-table-cell btn-group-vertical">
                    <button
                      class="btn btn-outline-primary btn-xs"
                      v-if="
                        item.req_info.fit_req_pk &&
                        item.req_info.state_cd == '0'
                      "
                      @click="fittingCancel(item.req_info.fit_req_pk)"
                    >
                      {{ $t("button.membership.cancel_fitting") }}
                    </button>
                    <button
                      class="btn btn-outline-primary btn-xs"
                      v-if="
                        item.req_info.fit_req_pk && item.req_info.state_cd > 0
                      "
                      @click="
                        $router.push({
                          name: 'FittingAnswerView',
                          params: {
                            fitreqno: item.req_info.fit_req_pk,
                          },
                          query: { goodstype: $route.query.goodstype },
                        })
                      "
                    >
                      {{ $t("button.membership.view_fitting") }}
                    </button>
                    <button
                      class="btn btn-outline-primary btn-xs"
                      v-if="
                        item.req_info.fit_req_pk && item.req_info.state_cd > 1
                      "
                      @click="
                        $router.push({
                          name: 'FittingSaleView',
                          params: {
                            fitansno: item.req_info.fit_ans_fk,
                          },
                          query: { goodstype: $route.query.goodstype },
                        })
                      "
                    >
                      {{ $t("button.membership.purchased_goods") }}
                    </button>
                    <button
                      class="btn btn-outline-primary btn-xs"
                      v-if="
                        item.req_info.fit_req_pk &&
                        item.req_info.fit_ans_fk != '0' &&
                        item.req_info.state_cd < 2
                      "
                      @click="buyCancel(item.req_info.fit_req_pk)"
                    >
                      {{ $t("button.membership.cancel_purchase") }}
                    </button>
                    <button
                      class="btn btn-outline-primary btn-xs"
                      v-if="
                        item.req_info.fit_req_pk && item.req_info.state_cd > 1
                      "
                    >
                      {{ $t("button.membership.view_delivery") }}
                    </button>
                  </td>
                  <td class="td-arrow" @click="toggle_tr(index)">
                    <img
                      src="/img/arrow_none_collapsed.png"
                      :class="{ 'btn-toggle-arrow': view_hide === index }"
                    />
                  </td>
                </tr>
                <tr
                  class="d-lg-none"
                  :class="{ show: view_hide === index }"
                  :key="'a' + index_ + 'r'"
                  v-if="view_hide === index"
                >
                  <td colspan="6">
                    <div class="toggle-content">
                      <div class="row">
                        <div class="col-3">
                          {{ $t("title.membership.club_info") }}
                        </div>
                        <div class="col-9">
                          <router-link
                            :to="{
                              name: 'FittingSwingList',
                              params: {
                                fittingno: item.fittingno,
                              },
                            }"
                          >
                            {{ $t("title.common.shaft") }}({{ item_.shaftname }}
                            /
                            {{ item_.shaftmaterial | get_shaft_material }}
                            /
                            {{ item_.shaftflextype | get_balance }})<br />
                            {{ $t("title.common.loft") }} ({{
                              item_.goodsloft || "-"
                            }}) {{ $t("title.common.lie") }} ({{
                              item_.goodslie || "-"
                            }}) {{ $t("title.common.sole") }} ({{
                              item_.goodssole || "-"
                            }}) FP ({{ item_.goodsfp || "-" }})
                            {{ $t("title.common.length") }} ({{
                              item_.goodslength || "-"
                            }}) {{ $t("title.common.total_weight") }} ({{
                              item_.goodsweight || "-"
                            }})
                          </router-link>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-3">
                          {{ $t("title.common.location") }}
                        </div>
                        <div class="col-9 shop-name">{{ item_.shopname }}</div>
                      </div>
                      <div class="row">
                        <div class="col-3">
                          {{ $t("title.membership.function") }}
                        </div>
                        <div class="col-9 text-date">
                          {{ $dayjs(item_.regdate).format("YYYY-MM-DD") }}
                        </div>
                      </div>
                    </div>
                    <!-- //toggle-content -->
                  </td>
                </tr>
              </template>
            </template>
          </template>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="6" class="text-center">
              {{ $t("text.common.no_data") }}
            </td>
          </tr>
        </tbody>
      </table>
      <b-pagination-nav
        :link-gen="linkGen"
        :number-of-pages="page_total"
        v-model="searchData.pageno"
        use-router
        aria-label="Page navigation"
        v-if="page_total > 1"
        first-class="prev"
        prev-class="prev"
        next-class="next"
        last-class="next"
        hide-ellipsis
        limit="10"
      >
        <template #first-text>
          <i class="material-icons">keyboard_double_arrow_left</i>
        </template>
        <template #prev-text>
          <i class="material-icons">navigate_before</i>
        </template>
        <template #next-text>
          <i class="material-icons">navigate_next</i>
        </template>
        <template #last-text>
          <i class="material-icons">keyboard_double_arrow_right</i>
        </template>
        <template #page="{ page, active }">
          <a v-if="active">{{ page }}</a>
          <a v-else>{{ page }}</a>
        </template>
      </b-pagination-nav>

      <div class="btn-bottom-wrapper">
        <b-button
          size="xl"
          variant="outline-primary"
          :aria-label="$t('button.membership.delete_selection')"
          @click="fittingDrop()"
          >{{ $t("button.membership.delete_selection") }}</b-button
        >
        <b-button
          size="xl"
          variant="primary"
          type="submit"
          :aria-label="$t('button.membership.create_group')"
          @click="groupCreate()"
          >{{ $t("button.membership.create_group") }}</b-button
        >
      </div>
    </div>
    <!-- //page -->
  </div>
</template>

<script>
import location from "@/components/Location";

import ax from "@/api/fitting";
import "@/filter/common";

export default {
  name: "FittingList",
  components: {
    location,
  },
  data() {
    return {
      searchData: {
        pageno:
          this.$route.query.pageno ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].pageno) ||
          1,
        searchname:
          this.$route.query.searchname ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].searchname) ||
          "",
        searchtype:
          this.$route.query.searchtype ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].searchtype) ||
          "G.fit_goods_nm",
        findex:
          this.$route.query.findex ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].findex) ||
          "F.reg_date",
      },
      groupFlag: [],
      page_total: 1,
      total_cnt: 0,
      rows: 10,
      items: [],
      view_hide: null,
      allSelected: false,
      checkedFitidx: [],
    };
  },
  methods: {
    onClickRedirect: function (ship_url) {
      window.open(ship_url, "_blank");
    },
    groupCreate() {
      if (!this.checkedFitidx.length) {
        alert(this.$i18n.t("text.common.no_items_selected"));
        return false;
      }

      if (this.checkedFitidx.length < 2) {
        alert(this.$i18n.t("text.membership.select_2_more_items"));
        return false;
      }

      ax.post_fittingGroup(this.checkedFitidx, (flag, response) => {
        alert(response.message);
        if (flag) {
          this.get_fittinglist();
        } else {
        }
      });
    },
    fittingCancel(fit_req_pk) {
      ax.delete_fittingCancel(fit_req_pk, (flag, response) => {
        alert(response.message);
        if (flag) {
          this.get_fittinglist();
        } else {
        }
      });
    },
    buyCancel(fit_req_pk) {
      ax.put_buyCancel(fit_req_pk, (flag, response) => {
        alert(response.message);
        if (flag) {
          this.get_fittinglist();
        } else {
        }
      });
    },
    groupDrop(fit_group_no) {
      ax.put_groupDrop(fit_group_no, (flag, response) => {
        alert(response.message);
        if (flag) {
          this.get_fittinglist();
        } else {
        }
      });
    },
    fittingDrop() {
      if (!this.checkedFitidx.length) {
        alert(this.$i18n.t("text.common.no_items_selected"));
        return false;
      }

      if (confirm(this.$i18n.t("alert.common.want_to_delete"))) {
        ax.post_fittingDrop(this.checkedFitidx, (flag, response) => {
          alert(response.message);
          if (flag) {
            this.get_fittinglist();
          } else {
          }
        });
      }
    },
    get_fittinglist() {
      this.$store.commit("setSearchData", {
        [this.$route.name]: this.searchData,
      });
      ax.get_fittinglist(this.searchData, (flag, data) => {
        this.total_cnt = data.fittinglistcnt;
        this.page_total = Math.ceil(data.fittinglistcnt / this.rows);

        this.items = data.fittinglist;
        if (!flag) alert(data.message);
      });
    },
    onSubmit(event) {
      event.preventDefault();
      this.searchData.pageno = 1;
      this.$store.commit("setSearchData", {
        [this.$route.name]: this.searchData,
      });
      this.get_fittinglist();
    },
    linkGen(pageNum) {
      return `?page=${pageNum}`;
    },

    toggle_tr(index) {
      if (this.view_hide === index) this.view_hide = null;
      else this.view_hide = index;
    },
    get_status_val(item) {
      if (!item.req_info.fit_req_pk)
        return this.$i18n.t("text.membership.fitting_apply");

      if (item.req_info.fit_req_pk && item.req_info.state_cd == "0")
        return this.$i18n.t("text.membership.fitting_ready");

      if (item.req_info.fit_req_pk && item.req_info.state_cd == "1")
        return this.$i18n.t("text.membership.fitting_done");

      if (item.req_info.fit_req_pk && item.req_info.state_cd == "2")
        return this.$i18n.t("text.membership.shipping_now");

      if (item.req_info.fit_req_pk && item.req_info.state_cd == "3")
        return this.$i18n.t("text.membership.shipping_done");
    },

    get_status_bg(item) {
      if (!item.req_info.fit_req_pk) return "bg-primary";

      if (item.req_info.fit_req_pk && item.req_info.state_cd == "0")
        return "bg-secondary";

      if (item.req_info.fit_req_pk && item.req_info.state_cd == "1")
        return "bg-success";

      if (item.req_info.fit_req_pk && item.req_info.state_cd == "2")
        return "bg-info";

      if (item.req_info.fit_req_pk && item.req_info.state_cd == "3")
        return "bg-warning";
    },
    selectAll() {
      this.checkedFitidx = [];
      if (!this.allSelected) {
        this.items.forEach((v) => this.checkedFitidx.push(v.fittingno));
      }
    },
    updateCheckall() {
      if (this.items.length == this.checkedFitidx.length) {
        this.allSelected = true;
      } else {
        this.allSelected = false;
      }
    },
  },
  created() {
    this.get_fittinglist();
  },
  watch: {
    "searchData.searchtype": function () {
      this.searchData.pageno = 1;
      this.get_fittinglist();
    },
    "searchData.searchname": function () {
      this.searchData.pageno = 1;
      this.get_fittinglist();
    },

    "searchData.pageno": function () {
      this.get_fittinglist();
    },
    "searchData.findex": function () {
      this.searchData.pageno = 1;
      this.get_fittinglist();
    },
  },
};
</script>
