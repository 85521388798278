<template>
  <div>
    <location />
    <div class="page">
      <tab />
      <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade show active" id="pills-4" role="tabpanel">
          <div class="wrap-1200">
            <b-form @submit="onSubmit" class="filter-group">
              <div class="input-group flex-nowrap">
                <div class="date-search flex-grow-1 flex-sm-grow-0 ms-sm-auto">
                  <input
                    type="date"
                    name="startdate"
                    v-model="searchData.startdate"
                  />
                  <input
                    type="date"
                    class="form-control-"
                    name="enddate"
                    v-model="searchData.enddate"
                  />
                </div>
                <button
                  class="btn btn-primary btn-md icon-search"
                  type="submit"
                ></button>
              </div>
            </b-form>

            <table class="table board toggle-xl">
              <thead>
                <tr>
                  <!-- <th class="d-none d-lg-table-cell text-nowrap w-1">
                    {{ $t("title.common.number") }}
                  </th> -->
                  <th class="d-lg-table-cell w-1">
                    {{ $t("title.common.swing_video") }}
                  </th>
                  <th
                    class="d-none d-md-table-cell col-md-5 col-lg-3 col-xxl-4"
                  >
                    {{ $t("title.common.title") }}
                  </th>
                  <th class="d-lg-table-cell">
                    {{ $t("title.common.date") }}
                  </th>
                  <th class="d-none d-lg-table-cell col-2">
                    {{ $t("title.common.location") }}
                  </th>
                  <th class="d-none d-xl-table-cell w-1">
                    {{ $t("title.common.status") }}
                  </th>
                  <th class="d-none d-xl-table-cell w-1">
                    {{ $t("title.common.control") }}
                  </th>
                  <th class="th-arrow">&nbsp;</th>
                </tr>
              </thead>
              <tbody v-if="total_cnt > 0">
                <template v-for="(item, index) in items">
                  <tr :key="index">
                    <!-- <td class="d-none d-lg-table-cell">{{ item.no }}</td> -->
                    <td class="d-lg-table-cell w-1">
                      <router-link
                        :to="{
                          name: 'MySwingView',
                          params: { id: item.swingno },
                          query: {
                            startdate: searchData.startdate,
                            enddate: searchData.enddate,
                          },
                        }"
                        class="position-relative video-box"
                      >
                        <video :src="item.path | get_img"></video>
                        <div class="mask"></div>
                        <span class="icon-play"></span>
                      </router-link>
                    </td>
                    <td
                      class="d-none d-md-table-cell col-md-5 col-lg-3 col-xxl-4 text-start"
                    >
                      <div class="d-flex align-items-center">
                        <router-link
                          :to="{
                            name: 'MySwingView',
                            params: { id: item.swingno },
                            query: {
                              startdate: searchData.startdate,
                              enddate: searchData.enddate,
                            },
                          }"
                          class="text-truncate more"
                        >
                          {{ item.title }}
                        </router-link>
                        <div
                          class="ms-1 ms-md-2 text-lightgray text-nowrap fs-14"
                          v-if="item.title && item.replycnt > 0"
                        >
                          <i class="material-icons-outlined fs-14">textsms</i>
                          {{ item.replycnt | comma }}
                        </div>
                      </div>
                    </td>
                    <td class="d-lg-table-cell text-date">
                      {{ item.timezone_date.timezone_private | dateformat }}
                    </td>
                    <td class="d-none d-lg-table-cell col-2 shop-name">
                      {{ item.shopname }}
                    </td>
                    <td class="d-none d-xl-table-cell text-nowrap">
                      {{
                        item.flag == "0"
                          ? $t("text.common.status_private")
                          : $t("text.common.status_public")
                      }}
                    </td>
                    <td class="d-none d-xl-table-cell">
                      <button
                        type="button"
                        class="btn btn-primary btn-xs w-100 mb-2"
                        @click="
                          $refs['modal-sv-mgmt'].modalOuterStyle.zIndex = 9999;
                          $refs['modal-sv-mgmt'].show();
                          sendData.swingno = item.swingno;
                          sendData.title = item.title;
                          sendData.content = item.content;
                          sendData.flag = item.flag;
                        "
                      >
                        {{ $t("button.common.edit") }}
                      </button>
                      <button
                        type="button"
                        class="btn btn-outline-secondary btn-xs w-100"
                        @click="delete_swing(item.swingno)"
                      >
                        {{ $t("button.common.delete") }}
                      </button>
                    </td>
                    <td class="td-arrow" @click="toggle_tr(index)">
                      <img
                        src="/img/arrow_none_collapsed.png"
                        :class="{ 'btn-toggle-arrow': view_hide === index }"
                      />
                    </td>
                  </tr>
                  <tr
                    class="d-xl-none"
                    :class="{ show: view_hide === index }"
                    :key="index + 'r'"
                    v-if="view_hide === index"
                  >
                    <td colspan="6">
                      <div class="toggle-content">
                        <!-- <div class="row">
                          <div class="col-2">{{ $t("title.common.number") }}</div>
                          <div class="col-10">{{ item.no }}</div>
                        </div> -->
                        <div class="row d-md-none">
                          <div class="col-3">
                            {{ $t("title.common.title") }}
                          </div>
                          <div class="col-9">
                            <span class="me-2">{{ item.title }}</span>
                            <span
                              class="text-lightgray text-nowrap fs-14"
                              v-if="item.title && item.replycnt > 0"
                            >
                              <i class="material-icons-outlined fs-14"
                                >textsms</i
                              >
                              {{ item.replycnt | comma }}
                            </span>
                          </div>
                        </div>
                        <div class="row d-lg-none">
                          <div class="col-3">
                            {{ $t("title.common.location") }}
                          </div>
                          <div class="col-9 shop-name">
                            {{ item.shopname }}
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-3">
                            {{ $t("title.common.status") }}
                          </div>
                          <div class="col-9">
                            {{
                              item.flag == "0"
                                ? $t("text.common.status_private")
                                : $t("text.common.status_public")
                            }}
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-3">
                            {{ $t("title.common.control") }}
                          </div>
                          <div class="col-9">
                            <button
                              type="button"
                              class="btn btn-primary btn-xs me-2"
                              @click="
                                $refs['modal-sv-mgmt'].show();
                                sendData.swingno = item.swingno;
                                sendData.title = item.title;
                                sendData.content = item.content;
                                sendData.flag = item.flag;
                              "
                            >
                              {{ $t("button.common.edit") }}
                            </button>

                            <button
                              type="button"
                              class="btn btn-outline-secondary btn-xs"
                              @click="delete_swing(item.swingno)"
                            >
                              {{ $t("button.common.delete") }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="6" class="text-center">
                    {{ $t("text.common.no_data") }}
                  </td>
                </tr>
              </tbody>
            </table>

            <b-pagination-nav
              :link-gen="linkGen"
              :number-of-pages="page_total"
              v-model="searchData.pageno"
              use-router
              aria-label="Page navigation"
              v-if="page_total > 1"
              first-class="prev"
              prev-class="prev"
              next-class="next"
              last-class="next"
              hide-ellipsis
              limit="10"
            >
              <template #first-text>
                <i class="material-icons">keyboard_double_arrow_left</i>
              </template>
              <template #prev-text>
                <i class="material-icons">navigate_before</i>
              </template>
              <template #next-text>
                <i class="material-icons">navigate_next</i>
              </template>
              <template #last-text>
                <i class="material-icons">keyboard_double_arrow_right</i>
              </template>
              <template #page="{ page, active }">
                <a v-if="active">{{ page }}</a>
                <a v-else>{{ page }}</a>
              </template>
            </b-pagination-nav>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-sv-mgmt"
      ref="modal-sv-mgmt"
      size="lg"
      centered
      scrollable
      footer-class="btn-bottom-wrapper"
    >
      <template #modal-header>
        <h3>{{ $t("title.common.swing_video_mgmt") }}</h3>
        <b-button
          variant="close"
          :aria-label="$t('button.common.close')"
          @click="$bvModal.hide('modal-sv-mgmt')"
        >
        </b-button>
      </template>
      <div class="row g-0 border-top-dgray border-bottom">
        <div class="col-lg-3 d-table bg-gray">
          <label class="d-table-cell required">{{
            $t("title.common.title")
          }}</label>
        </div>
        <div class="col-lg d-table">
          <input type="text" class="form-control-lg" v-model="sendData.title" />
        </div>
      </div>

      <div class="row g-0 border-bottom">
        <div class="col-lg-3 d-table bg-gray">
          <label class="d-table-cell">{{ $t("title.common.contents") }}</label>
        </div>
        <div class="col-lg d-table">
          <!-- <input
            type="text"
            class="form-control-lg"
            v-model="sendData.content"
          /> -->
          <textarea name="contents" id="" v-model="sendData.content"></textarea>
        </div>
      </div>
      <div class="row g-0 border-bottom">
        <div class="col-lg-3 d-table bg-gray">
          <label class="d-table-cell">{{ $t("title.common.status") }}</label>
        </div>
        <div class="col-lg d-table">
          <div class="form-check d-inline-block me-3">
            <input
              class="form-check-input"
              type="radio"
              id="Private"
              value="0"
              v-model="sendData.flag"
              :checked="sendData.flag == '0'"
            />
            <label class="form-check-label" for="Private">{{
              $t("text.common.status_private")
            }}</label>
          </div>
          <div class="form-check d-inline-block">
            <input
              class="form-check-input"
              type="radio"
              id="Public"
              value="1"
              v-model="sendData.flag"
              :checked="sendData.flag == '1'"
            />
            <label class="form-check-label" for="Public">{{
              $t("text.common.status_public")
            }}</label>
          </div>
        </div>
      </div>
      <template #modal-footer="{}">
        <b-button
          size="md"
          variant="outline-secondary"
          aria-label="Cancel"
          @click="$bvModal.hide('modal-sv-mgmt')"
          >{{ $t("button.common.cancel") }}</b-button
        >
        <b-button
          size="md"
          variant="secondary"
          aria-label="Edit"
          @click="confirm"
          >{{ $t("button.common.save") }}</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import location from "@/components/Location";
import tab from "@/components/membership/Tab";
import ax from "@/api/membership";

export default {
  name: "MySwing",
  components: {
    location,
    tab,
  },
  data() {
    return {
      searchData: {
        pageno: parseInt(this.$route.query.page) || 1,
        startdate:
          this.$route.query.startdate ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].startdate) ||
          this.$dayjs(new Date().setMonth(new Date().getMonth() - 1)).format(
            "YYYY-MM-DD"
          ),
        enddate:
          this.$route.query.enddate ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].enddate) ||
          this.$dayjs(new Date()).format("YYYY-MM-DD"),
      },
      sendData: {
        swingno: 0,
        content: "",
        title: "",
        flag: 0,
      },
      page_total: 1,

      total_cnt: 0,
      data_yn: true,
      rows: 10,
      items: [],
      view_hide: null,
    };
  },
  methods: {
    get_memberswinglist() {
      this.$store.commit("setSearchData", {
        [this.$route.name]: this.searchData,
      });
      ax.get_memberswinglist(this.searchData, (flag, data, code = "") => {
        if (flag) {
          this.total_cnt = data.swinglistcnt;
          this.page_total = Math.ceil(data.swinglistcnt / this.rows);
          this.items = data.swinglist;
        } else {
          this.page_total = 0;

          // alert(data);
        }
      });
    },
    onSubmit(event) {
      event.preventDefault();
      this.searchData.pageno = 1;
      this.$router
        .push({
          query: {
            startdate: this.searchData.startdate,
            enddate: this.searchData.enddate,
          },
        })
        .catch(() => {});
    },
    linkGen(pageNum) {
      return `?page=${pageNum}`;
    },
    toggle_tr(index) {
      if (this.view_hide === index) this.view_hide = null;
      else this.view_hide = index;
    },
    change_flag(idx, change_flag) {
      console.log(idx, change_flag);
      ax.set_memberswingopen(idx, change_flag, (flag, data) => {
        if (flag) {
          this.get_memberswinglist();
        } else {
          alert(data.message);
        }
      });
    },
    confirm() {
      if (this.sendData.title === "") {
        alert(
          this.$i18n.t("alert.common.field_enter", {
            field: this.$i18n.t("title.common.title"),
          })
        );

        return false;
      }

      ax.set_swingtitlewrite(
        this.sendData.swingno,
        this.sendData.title,
        this.sendData.content,
        this.sendData.flag,
        (flag, data) => {
          if (flag) {
            this.change_flag(this.sendData.swingno, this.sendData.flag);
            this.$refs["modal-sv-mgmt"].hide();
            this.sendData.swingno = 0;
            this.sendData.title = "";
            this.sendData.content = "";
            this.sendData.flag = 0;
          } else {
            alert(data);
          }
        }
      );
    },
    delete_swing(idx) {
      ax.memberswingdrop(idx, (flag, data) => {
        alert(data);
        this.get_memberswinglist();
      });
    },
  },
  created() {
    this.get_memberswinglist();
  },
  watch: {
    "searchData.startdate": function () {
      this.searchData.pageno = 1;
      this.get_memberswinglist();
    },
    "searchData.enddate": function () {
      this.searchData.pageno = 1;
      this.get_memberswinglist();
    },

    "searchData.pageno": function () {
      this.get_memberswinglist();
    },
  },
};
</script>
