<template>
  <div>
    <location />

    <div class="page">
      <tab />

      <div class="tab-content" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="pills-1"
          role="tabpanel"
          aria-labelledby="pills-1-tab"
        >
          <div class="wrap-1200">
            <scorecard
              v-bind:score-card-info="scorecardinfo"
              v-bind:score-card-stats="scorecardstats"
              v-bind:score-card-total="scorecardtotal"
              v-bind:score-card-hole-par="scorecardholepar"
              v-bind:score-card-score="scorecardscore"
              v-bind:sub-course-list="subcourselist"
            />
          </div>

          <div class="wrap-1200">
            <div class="row g-0 border-top-dgray">
              <div class="col-lg-6 border-bottom">
                <div class="row g-0">
                  <div class="col bg-gray row g-0 align-item-center">
                    <label class="align-center d-table-cell">
                      {{ $t("title.common.max_distance") }}
                    </label>
                  </div>
                  <div class="col">
                    <p class="d-table-cell">
                      {{ scorecardstats.maxdriver }}
                      {{ scorecardstats.maxdriver ? "m" || "" : "" }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 border-bottom">
                <div class="row g-0">
                  <div class="col bg-gray row g-0 align-item-center">
                    <label class="align-center d-table-cell">
                      {{ $t("title.common.avg_distance") }}
                    </label>
                  </div>
                  <div class="col">
                    <p class="d-table-cell">
                      {{ scorecardstats.avgdriver }}
                      {{ scorecardstats.avgdriver ? "m" || "" : "" }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 border-bottom">
                <div class="row g-0">
                  <div class="col bg-gray row g-0 align-item-center">
                    <label class="d-table-cell"
                      >{{ $t("title.common.score") }}({{
                        $t("title.common.shot")
                      }})</label
                    >
                  </div>
                  <div class="col">
                    <p class="d-table-cell">
                      <!-- {{
                        Math.sign(
                          scorecardstats.score - scorecardstats.base_par
                        ) > 0
                          ? "+"
                          : ""
                      }}{{ scorecardstats.score - scorecardstats.base_par }} -->
                      {{
                        Math.sign(
                          scorecardstats.score - scorecardstats.base_par
                        ) > 0
                          ? "+"
                          : ""
                      }}{{ scorecardstats.score - scorecardstats.base_par }}
                      <small>({{ scorecardstats.score }}) </small>
                      <!-- ( {{ scorecardtotal.mulligantotal || 0 }} ) -->
                      <!-- {{ scorecardtotal.mulligantotal ? "(" || "" : "" }}
                      {{ scorecardtotal.mulligantotal }}
                      {{ scorecardtotal.mulligantotal ? ")" || "" : "" }} -->
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 border-bottom">
                <div class="row g-0">
                  <div class="col bg-gray row g-0 align-item-center">
                    <label class="align-center d-table-cell">
                      {{ $t("title.common.avg_pph") }}
                    </label>
                  </div>
                  <div class="col">
                    <p class="d-table-cell">
                      {{ scorecardstats.avggreenhit | comma }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 border-bottom">
                <div class="row g-0">
                  <div class="col bg-gray row g-0 align-item-center">
                    <label class="align-center d-table-cell">{{
                      $t("title.common.fareway_hit")
                    }}</label>
                  </div>
                  <div class="col">
                    <p class="d-table-cell">
                      {{ scorecardstats.farewayrate }}
                      {{ scorecardstats.farewayrate ? "%" || "" : "" }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 border-bottom">
                <div class="row g-0">
                  <div class="col bg-gray row g-0 align-item-center">
                    <label class="align-center d-table-cell">{{
                      $t("title.common.gir")
                    }}</label>
                  </div>
                  <div class="col">
                    <p class="d-table-cell">
                      {{ scorecardstats.greenrate }}
                      {{ scorecardstats.greenrate ? "%" || "" : "" }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--      <div class="wrap-1200 start-m row g-0 center-btn">
            <div class="col-lg-4 d-table m-auto">
              <button
                class="btn btn-primary btn-xl"
                type="button"
                @click="click_list()"
              >
                목록
              </button>
            </div>
          </div> -->

          <div class="btn-bottom-wrapper">
            <b-button
              size="xl"
              variant="outline-primary"
              aria-label="목록"
              @click="click_list()"
              >{{ $t("button.common.list") }}</b-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import tab from "@/components/membership/Tab";
import scorecard from "@/components/membership/Scorecard";
import ax from "@/api/membership";
import "@/filter/common";
import { myMixin } from "@/mixin/myMixin";

export default {
  name: "MyScorecard",
  components: {
    location,
    tab,
    scorecard,
  },
  data() {
    return {
      searchData: {
        gameno: this.$route.query.gameno,
      },
      tournamentno: this.$route.params.id,
      scorecardinfo: [
        {
          coursename: "",
          date: "",
          nickname: "",
          playtype: "",
          shopname: "",
        },
      ],
      scorecardstats: [
        {
          maxdriver: 0,
          avgdriver: 0,
          shot: 0,
          avggreenhit: 0,
          farewayrate: 0,
          greenrate: 0,
        },
      ],
      scorecardholepar: [],
      scorecardscore: [],
      // partotal1, partotal2, shottotal1, shottotal2, scoretotal1, scoretotal2, mulligantotal1, mulligantotal2, mulligantotal
      scorecardtotal: [
        {
          partotal1: 0,
          partotal2: 0,
          shottotal1: 0,
          shottotal2: 0,
          scoretotal1: 0,
          scoretotal2: 0,
          mulligantotal1: 0,
          mulligantotal2: 0,
          mulligantotal: 0,
        },
      ],
      scroll: "r",
      hold: false,
      inter_clear: Object,
      subcourselist: [],
      subcourselistcnt: 0,
    };
  },
  methods: {
    get_memberscorecard() {
      ax.get_memberscorecard(this.searchData, (flag, data) => {
        if (flag) {
          this.scorecardinfo = data.scorecardinfo;
          this.scorecardstats = data.scorecardstats;
          this.scorecardholepar = data.scorecardholepar;
          this.scorecardscore = data.scorecardscore;
          this.scorecardtotal = data.scorecardtotal;
          this.subcourselist = data.subcourselist;
          this.subcourselistcnt = data.subcourselistcnt;
        } else {
          alert(data);
        }
      });
    },
    click_list() {
      this.$router.push({
        name: "MyTournamentsAttend",
        params: { id: this.tournamentno },
      });
    },
    scroll_x() {
      let content = document.querySelector(".list");
      let scrollAmount = 0;
      if (this.scroll === "r") {
        //content.scrollLeft += 40
        scrollAmount = 0;
        let slideTimer = setInterval(function () {
          content.scrollLeft += 40;
          scrollAmount += 40;
          if (scrollAmount >= 100) {
            window.clearInterval(slideTimer);
          }
        }, 25);
      } else {
        //content.scrollLeft -= 40
        scrollAmount = 0;
        let slideTimer = setInterval(function () {
          content.scrollLeft -= 40;
          scrollAmount += 40;
          if (scrollAmount >= 100) {
            window.clearInterval(slideTimer);
          }
        }, 25);
      }
    },
    course_click(direction) {
      let scrollAmount = 0;
      let content = document.querySelector(".list");
      if (direction === "r") {
        scrollAmount = 0;
        let slideTimer = setInterval(function () {
          content.scrollLeft += 40;
          scrollAmount += 40;
          if (scrollAmount >= 100) {
            window.clearInterval(slideTimer);
          }
        }, 25);
      } else {
        scrollAmount = 0;
        let slideTimer = setInterval(function () {
          content.scrollLeft -= 40;
          scrollAmount += 40;
          if (scrollAmount >= 100) {
            window.clearInterval(slideTimer);
          }
        }, 25);
      }
    },
  },
  created() {
    this.get_memberscorecard();
  },
  watch: {
    hold: function () {
      if (this.hold) {
        this.inter_clear = setInterval(this.scroll_x, 1000);
      } else {
        clearInterval(this.inter_clear);
      }
    },
  },
  mixins: [myMixin],
};
</script>
