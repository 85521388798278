<template>
  <div>
    <location />
    <div class="page">
      <tab />
      <div class="tab-content wrap-1200" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="pills-2"
          role="tabpanel"
          aria-labelledby="pills-2-tab"
        >
          <h3 class="subtitle">
            <span class="mb-2 icon badge bg-pink text-capitalize">
              {{ event_type(eventinfo.award_fk) }} </span
            ><br />
            {{ eventinfo.title }}
          </h3>

          <div class="mb-32px border">
            <h6 class="p-3 bg-lightgray border-bottom">
              {{ $t("title.common.prizes") }}
            </h6>
            <div class="row row-cols-1 row-cols-sm-3 g-2 g-sm-3 p-2 p-sm-3">
              <template v-for="(item, index) in awardprizeranklist">
                <div :key="'a' + index" class="col">
                  <div class="card h-100">
                    <div class="card-body">
                      <h6 class="card-title fs-6">
                        {{ $t("title.common.top_rank") }} {{ item.rank_no }}
                      </h6>
                      <p class="card-text pt-1 lh-sm text-point">
                        {{ item.prize }}
                      </p>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>

          <div class="row g-0 myrank">
            <div class="col">
              <ul class="box">
                <li>
                  <span class="rounded-circle bg-purple">
                    <i class="material-icons">military_tech</i>
                  </span>
                </li>
                <!-- <li v-if="data_yn"> -->
                <li>
                  <h2>
                    {{
                      rankInfo &&
                      rankInfo.eventrankinfo &&
                      rankInfo.eventrankinfo.rank | comma
                    }}<small>/{{ total_cnt || 0 }}</small>
                  </h2>
                  <p>{{ $t("text.common.my_ranking") }}</p>
                </li>
              </ul>
            </div>

            <div class="col">
              <ul class="box">
                <li>
                  <span class="rounded-circle bg-pink">
                    <i class="material-icons">scoreboard</i>
                  </span>
                </li>
                <!-- <li v-if="data_yn"> -->
                <li>
                  <h2>
                    {{
                      rankInfo &&
                      rankInfo.eventrankinfo &&
                      rankInfo.eventrankinfo.longest | comma
                    }}<small> m</small>
                  </h2>
                  <p>{{ $t("text.common.distance") }}</p>
                </li>
              </ul>
            </div>

            <div class="col">
              <ul class="box">
                <li>
                  <span class="rounded-circle bg-orange">
                    <i class="material-icons">flag</i>
                  </span>
                </li>
                <!-- <li v-if="data_yn"> -->
                <li>
                  <h2>
                    {{
                      rankInfo &&
                      rankInfo.eventrankinfo &&
                      rankInfo.eventrankinfo.entrycnt | comma
                    }}<small
                      >/{{
                        rankInfo &&
                        rankInfo.eventrankinfo &&
                        rankInfo.eventrankinfo.entrylimit | transToValue("∞")
                      }}</small
                    >
                  </h2>
                  <p>{{ $t("text.common.no_of_play") }}</p>
                </li>
              </ul>
            </div>
          </div>

          <!-- <b-form
            @submit="onSubmit"
            class="col-sm-6 col-lg-3 filter-group ms-auto bg-lime"
          >
            <div class="input-group">
              <select
                class="form-select"
                aria-label="Select one"
                name="gender"
                v-model="searchData.gender"
              >
                <option value="0" :selected="searchData.gender === 0">
                  {{ $t("filter.common.total") }}
                </option>
                <option value="1" :selected="searchData.gender === 1">
                  {{ $t("filter.common.male") }}
                </option>
                <option value="2" :selected="searchData.gender === 2">
                  {{ $t("filter.common.female") }}
                </option>
              </select>
              <button
                class="btn btn-primary btn-md icon-search"
                type="submit"
              ></button>
            </div>
          </b-form> -->

          <div id="mytour-tabContent">
            <div
              class="tab-pane fade show active"
              id="mytour-1"
              role="tabpanel"
              aria-labelledby="mytour-1-tab"
            >
              <table class="table board">
                <thead>
                  <tr v-if="eventinfo.award_fk == '11'">
                    <th class="col-auto d-lg-table-cell">
                      {{ $t("title.common.ranking") }}
                    </th>

                    <th class="col-auto d-lg-table-cell">
                      {{ $t("title.common.nickname") }}
                    </th>
                    <th class="col-auto d-none d-lg-table-cell">
                      {{ $t("title.common.course") }}
                    </th>
                    <th class="col-auto d-none d-lg-table-cell">
                      {{ $t("title.common.hole") }}
                    </th>
                    <th class="col-auto d-none d-lg-table-cell">
                      {{ $t("title.common.location") }}
                    </th>
                    <th class="col-auto d-none d-lg-table-cell">
                      {{ $t("title.common.distance") }}
                    </th>
                    <th class="col-auto d-none d-lg-table-cell">
                      {{ $t("title.common.no_of_play") }}
                    </th>
                    <th class="th-arrow"></th>
                  </tr>
                  <tr v-if="eventinfo.award_fk == '12'">
                    <th class="col-auto d-lg-table-cell">
                      {{ $t("title.common.ranking") }}
                    </th>
                    <th class="col-auto d-lg-table-cell">
                      {{ $t("title.common.nickname") }}
                    </th>
                    <th class="col-auto d-none d-lg-table-cell">
                      {{ $t("title.common.course") }}
                    </th>
                    <th class="col-auto d-none d-lg-table-cell">
                      {{ $t("title.common.hole") }}
                    </th>
                    <th class="col-auto d-none d-lg-table-cell">
                      {{ $t("title.common.location") }}
                    </th>
                    <th class="col-auto d-none d-lg-table-cell">
                      {{ $t("title.common.distance") }}
                    </th>
                    <th class="col-auto d-none d-lg-table-cell">
                      {{ $t("title.common.no_of_play") }}
                    </th>
                    <!-- <th class="col-auto d-none d-lg-table-cell">
              Final Score<br />(Correction value)
            </th> -->
                    <th class="th-arrow"></th>
                  </tr>
                </thead>

                <tbody v-if="data_yn && eventinfo.award_fk == '11'">
                  <template v-for="(item, index) in items">
                    <tr :key="index">
                      <td class="col-auto d-lg-table-cell">
                        {{ item.eventwinningrankinfo.rank }}
                      </td>
                      <td class="col-auto d-lg-table-cell">
                        <MemberInfo
                          :nickname="item.eventwinningrankinfo.nickname"
                          :playerno="item.eventwinningrankinfo.playerno"
                        />
                      </td>
                      <td class="col-auto d-none d-lg-table-cell">
                        <template v-for="(course, index_n) in item.coursescore">
                          <div :key="index_n + 'l1'">
                            {{ course.coursename }}
                            {{ course.subcoursename }}
                          </div>
                        </template>
                      </td>
                      <td class="col-auto d-none d-lg-table-cell">
                        {{ item.eventwinningrankinfo.hole }}
                      </td>
                      <td class="col-auto d-none d-lg-table-cell shop-name">
                        {{ item.eventwinningrankinfo.shopname }}
                      </td>
                      <td class="col-auto d-none d-lg-table-cell">
                        {{ item.eventwinningrankinfo.longest }}m
                      </td>
                      <td class="col-auto d-none d-lg-table-cell text-red">
                        {{ item.eventwinningrankinfo.entrycnt }} /
                        {{
                          item.eventwinningrankinfo.entrylimit
                            | transToValue("∞")
                        }}
                      </td>
                      <td class="td-arrow" @click="toggle_tr(index)">
                        <img
                          src="/img/arrow_none_collapsed.png"
                          :class="{ 'btn-toggle-arrow': view_hide === index }"
                        />
                      </td>
                    </tr>
                    <tr
                      class="d-lg-none"
                      :class="{ show: view_hide === index }"
                      :key="index + 'r'"
                      v-if="view_hide === index"
                    >
                      <td colspan="7">
                        <div class="toggle-content">
                          <div class="row">
                            <div class="col-4">
                              {{ $t("title.common.course") }}
                            </div>
                            <div class="col-8">
                              <template
                                v-for="(course, index_nm) in item.coursescore"
                              >
                                <p :key="index_nm + 'm'">
                                  {{ course.coursename }}
                                  {{ course.subcoursename }}
                                </p>
                              </template>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-4">
                              {{ $t("title.common.hole") }}
                            </div>
                            <div class="col-8">
                              {{ item.eventwinningrankinfo.hole }}
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-4">
                              {{ $t("title.common.location") }}
                            </div>
                            <div class="col-8 shop-name">
                              {{ item.eventwinningrankinfo.shopname }}
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-4">
                              {{ $t("title.common.distance") }}
                            </div>
                            <div class="col-8">
                              {{ item.eventwinningrankinfo.longest }}m
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-4">
                              {{ $t("title.common.no_of_play") }}
                            </div>
                            <div class="col-8 text-red">
                              {{ item.eventwinningrankinfo.entrycnt }} /
                              {{
                                item.eventwinningrankinfo.entrylimit
                                  | transToValue("∞")
                              }}
                            </div>
                          </div>
                          <!-- <div class="row">
                    <div class="col-6">Final Score<br />(Correction value)</div>
                    <div class="col-6">
                      {{ item.eventwinningrankinfo.newperiolastscore | comma }}
                    </div>
                  </div> -->
                        </div>
                      </td>
                    </tr>
                  </template>
                </tbody>
                <tbody v-else-if="data_yn && eventinfo.award_fk == '12'">
                  <template v-for="(item, index) in items">
                    <tr :key="index">
                      <td class="col-auto d-lg-table-cell">
                        {{ item.eventwinningrankinfo.rank }}
                      </td>
                      <td class="col-auto d-lg-table-cell">
                        <MemberInfo
                          :nickname="item.eventwinningrankinfo.nickname"
                          :playerno="item.eventwinningrankinfo.playerno"
                        />
                      </td>
                      <td class="col-auto d-none d-lg-table-cell">
                        <template
                          v-for="(course, index_nm) in item.coursescore"
                        >
                          <div :key="index_nm + 'm'">
                            {{ course.coursename }} {{ course.subcoursename }}
                          </div>
                        </template>
                      </td>
                      <td class="col-auto d-none d-lg-table-cell">
                        {{ item.eventwinningrankinfo.hole }}
                      </td>
                      <td class="col-auto d-none d-lg-table-cell shop-name">
                        {{ item.eventwinningrankinfo.shopname }}
                      </td>
                      <td class="col-auto d-none d-lg-table-cell">
                        {{ item.eventwinningrankinfo.nearpin }}m
                      </td>
                      <td class="col-auto d-none d-lg-table-cell text-red">
                        {{ item.eventwinningrankinfo.entrycnt }} /
                        {{
                          item.eventwinningrankinfo.entrylimit
                            | transToValue("∞")
                        }}
                      </td>
                      <!-- <td class="col-auto d-none d-lg-table-cell">
                {{ item.eventwinningrankinfo.newperiolastscore | comma }}
              </td> -->
                      <td class="td-arrow" @click="toggle_tr(index)">
                        <img
                          src="/img/arrow_none_collapsed.png"
                          :class="{ 'btn-toggle-arrow': view_hide === index }"
                        />
                      </td>
                    </tr>
                    <tr
                      class="d-lg-none"
                      :class="{ show: view_hide === index }"
                      :key="index + 'r'"
                      v-if="view_hide === index"
                    >
                      <td colspan="7">
                        <div class="toggle-content">
                          <div class="row">
                            <div class="col-4">
                              {{ $t("title.common.course") }}
                            </div>
                            <div class="col-8">
                              <template
                                v-for="(course, index_n) in item.coursescore"
                              >
                                <p :key="index_n + 'l1'">
                                  {{ course.coursename }}
                                  {{ course.subcoursename }}
                                </p>
                              </template>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-4">
                              {{ $t("title.common.location") }}
                            </div>
                            <div class="col-8 shop-name">
                              {{ item.eventwinningrankinfo.shopname }}
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-4">
                              {{ $t("title.common.distance") }}
                            </div>
                            <div class="col-8">
                              {{ item.eventwinningrankinfo.nearpin }}m
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-4">
                              {{ $t("title.common.no_of_play") }}
                            </div>
                            <div class="col-8 text-red">
                              {{ item.eventwinningrankinfo.entrycnt }} /
                              {{
                                item.eventwinningrankinfo.entrylimit
                                  | transToValue("∞")
                              }}
                            </div>
                          </div>
                          <!-- <div class="row">
                    <div class="col-6">Final Score<br />(Correction value)</div>
                    <div class="col-6">
                      {{ item.eventwinningrankinfo.newperiolastscore | comma }}
                    </div>
                  </div> -->
                        </div>
                      </td>
                    </tr>
                  </template>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="7" class="text-center">
                      {{ $t("text.common.no_data") }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <b-pagination-nav
                :link-gen="linkGen"
                :number-of-pages="page_total"
                v-model="searchData.pageno"
                use-router
                aria-label="Page navigation"
                v-if="page_total > 1"
                first-class="prev"
                prev-class="prev"
                next-class="next"
                last-class="next"
                hide-ellipsis
                limit="10"
              >
                <template #first-text>
                  <i class="material-icons">keyboard_double_arrow_left</i>
                </template>
                <template #prev-text>
                  <i class="material-icons">navigate_before</i>
                </template>
                <template #next-text>
                  <i class="material-icons">navigate_next</i>
                </template>
                <template #last-text>
                  <i class="material-icons">keyboard_double_arrow_right</i>
                </template>
                <template #page="{ page, active }">
                  <a v-if="active">{{ page }}</a>
                  <a v-else>{{ page }}</a>
                </template>
              </b-pagination-nav>

              <div class="btn-bottom-wrapper">
                <b-button
                  size="xl"
                  variant="outline-primary"
                  :aria-label="$t('button.common.list')"
                  @click="$router.push({ name: 'MyEvent' }).catch(() => {})"
                >
                  {{ $t("button.common.list") }}
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import tab from "@/components/membership/Tab";
import MemberInfo from "@/components/membership/MemberInfo";
import ax from "@/api/membership";
import tax from "@/api/tournament";
import "@/filter/common";
import { myMixin } from "@/mixin/myMixin";

export default {
  name: "MyMiniGameRanking",
  components: {
    location,
    tab,
    MemberInfo,
  },
  data() {
    return {
      searchData: {
        gender:
          this.$route.query.gender ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].gender) ||
          0,
        pageno:
          this.$route.query.pageno ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].pageno) ||
          1,
        eventno:
          this.$route.params.eventno ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].eventno) ||
          0,
      },
      page_total: 1,
      total_cnt: 0,
      data_yn: true,
      rows: 10,
      items: [],
      view_hide: null,
      eventinfo: {},
      awardprizeranklist: [],
      rankInfo: [],
    };
  },
  methods: {
    get_eventwinningranklist() {
      ax.get_eventwinningranklist(this.searchData, (flag, data, code = "") => {
        // console.log(flag, data);
        if (flag) {
          if (data.eventwinningrankcnt <= 0) {
            this.data_yn = false;
          } else {
            this.data_yn = true;
          }
          this.total_cnt = data.eventwinningrankcnt;
          this.page_total = Math.ceil(data.eventwinningrankcnt / this.rows);
          this.items = data.eventwinningranklist;
          this.eventinfo = data.eventinfo;
          console.log(data.eventinfo);
          this.awardprizeranklist = data.awardprizeranklist;
          this.rankInfo = data.player;
        } else {
          this.data_yn = false;
          this.page_total = 0;

          alert(data.message);
        }
      });
    },
    linkGen(pageNum) {
      return `?page=${pageNum}`;
    },
    toggle_tr(index) {
      if (this.view_hide === index) this.view_hide = null;
      else this.view_hide = index;
    },
    onSubmit(event) {
      event.preventDefault();
      this.searchData.pageno = 1;
      this.$router
        .push({
          query: {
            scoretype: this.searchData.scoretype,
            gender: this.searchData.gender,
          },
        })
        .catch(() => {});
    },

    getMemberInfo(playerno) {
      tax.get_playerinfo(playerno, (flag, data) => {
        if (flag) {
          this.member.playerno = playerno;
          this.member.playerbasicinfo = data.playerbasicinfo;
          this.member.playerpalyinfo = data.playerpalyinfo;
          this.$refs["modal-info-member"].modalOuterStyle.zIndex = 9999;
          this.$refs["modal-info-member"].show();
        } else {
          alert(data);
        }
      });
    },
  },
  created() {
    this.get_eventwinningranklist();
  },
  watch: {
    // "$route.query": function () {
    //   this.searchData.pageno = parseInt(this.$route.query.page) || 1;
    //   this.get_eventwinningranklist();
    // },
    searchData: {
      handler: function (val, oldVal) {
        this.$store.commit("setSearchData", {
          [this.$route.name]: val,
        });
        this.get_eventwinningranklist();
      },
      deep: true,
    },
  },

  mixins: [myMixin],
};
</script>
