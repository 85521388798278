<template>
  <div id="scorecard">
    <div class="round-summary">
      <h3 class="subtitle mb-2">
        {{ $t("title.membership.scorecard") }}
        <!-- <MemberInfo :nickname="scoreCardInfo.nickname" class="float-start" />
        님의 스코어카드 -->
      </h3>
      <div class="d-flex">
        <div class="flex-grow-1">
          <!-- <p>{{ scoreCardInfo.shopname }}</p> -->

          <p class="text-date">
            {{
              scoreCardInfo.timezone_date &&
              scoreCardInfo.timezone_date.timezone_common | dateformat
            }}
          </p>

          <span class="badge type-play stroke"
            >{{ play_type(scoreCardInfo.playtype) }}
          </span>
          <!-- <span class="badge type-play newperio">new perio</span> -->
        </div>
        <div class="flex-shrink-0 total-score">
          {{
            Math.sign(scoreCardStats.score - scoreCardStats.base_par) > 0
              ? "+"
              : ""
          }}{{ scoreCardStats.score - scoreCardStats.base_par
          }}<small>({{ scoreCardStats.score }})</small>
          <!-- <small>({{ scoreCardTotal.mulligantotal || 0 }}) </small> -->
        </div>
      </div>
    </div>

    <div
      class="round-record"
      v-for="(anc, index1) in subCourseList"
      :key="index1"
    >
      <h6>
        {{ scoreCardInfo.coursename }} -
        {{ subCourseList[index1].subcoursename }}
      </h6>
      <b-table-simple bordered responsive>
        <b-tbody>
          <b-tr>
            <b-th sticky-column>{{ $t("title.common.hole") }}</b-th>
            <b-td
              v-for="(anc_, index2) in anc.scorecardscore"
              :key="index2"
              class="col-1"
            >
              {{ anc_.hole_no }}
            </b-td>
            <b-td v-if="subCourseList[index1].subcourseseq == 1" class="col-1">
              {{ $t("title.common.out") }}
            </b-td>
            <b-td v-else class="col-1">
              {{ $t("title.common.in") }}
            </b-td>
          </b-tr>

          <b-tr>
            <b-th sticky-column>{{ $t("title.common.par") }}</b-th>
            <b-td v-for="(anc_, index2) in anc.scorecardscore" :key="index2">
              {{ anc_.par }}
            </b-td>
            <b-td v-if="index1 == 0">
              {{ scoreCardTotal.partotal1 }}
            </b-td>
            <b-td v-else>
              {{ scoreCardTotal.partotal2 }}
            </b-td>
          </b-tr>

          <b-tr>
            <b-th sticky-column>{{ $t("title.common.shot") }}</b-th>
            <b-td v-for="(anc_, index2) in anc.scorecardscore" :key="index2">
              {{ anc_.shot ? anc_.shot : 0 }}
            </b-td>
            <b-td v-if="index1 == 0">
              {{ scoreCardTotal.shottotal1 }}
            </b-td>
            <b-td v-else>
              {{ scoreCardTotal.shottotal2 }}
            </b-td>
          </b-tr>

          <b-tr>
            <b-th sticky-column>{{ $t("title.common.score") }}</b-th>
            <b-td v-for="(anc_, index2) in anc.scorecardscore" :key="index2">
              <span
                :class="[
                  {
                    par: anc_.score == 0 ? true : false,
                  },
                  {
                    eagle: anc_.score == -2 ? true : false,
                  },
                  {
                    birdie: anc_.score == -1 ? true : false,
                  },
                  {
                    bogey: anc_.score == 1 ? true : false,
                  },
                  {
                    'd-bogey': anc_.score == 2 ? true : false,
                  },
                  {
                    't-bogey-over': anc_.score == 3 ? true : false,
                  },
                  {
                    'bg-white': anc_.score == '' ? true : false,
                  },
                ]"
              >
                {{ anc_.score }}
              </span>
            </b-td>
            <b-td v-if="index1 == 0">
              {{ scoreCardTotal.scoretotal1 }}
            </b-td>
            <b-td v-else>
              {{ scoreCardTotal.scoretotal2 }}
            </b-td>
          </b-tr>

          <b-tr>
            <b-th sticky-column>{{ $t("title.common.handicap") }}</b-th>
            <b-td v-for="(anc_, index2) in anc.scorecardscore" :key="index2">
              {{ anc_.handicap || 0 }}
            </b-td>
            <b-td> - </b-td>
          </b-tr>

          <b-tr>
            <b-th sticky-column>{{ $t("title.common.mulligan") }}</b-th>
            <b-td v-for="(anc_, index2) in anc.scorecardscore" :key="index2">
              {{ anc_.mulligan || 0 }}
            </b-td>
            <b-td v-if="index1 == 0">
              {{ scoreCardTotal.mulligantotal1 }}
            </b-td>
            <b-td v-else>
              {{ scoreCardTotal.mulligantotal2 }}
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
    <!-- // round-record -->

    <div class="d-flex justify-content-end">
      <div class="box pc-none">
        <p class="eagle"><span></span>{{ $t("text.common.eagle") }}</p>
        <p class="birdie"><span></span>{{ $t("text.common.birdie") }}</p>
        <p class="par"><span></span>{{ $t("text.common.par") }}</p>
        <p class="bogey"><span></span>{{ $t("text.common.bogey") }}</p>
        <p class="d-bogey"><span></span>{{ $t("text.common.double_bogey") }}</p>
        <p class="t-bogey-over">
          <span></span>{{ $t("text.common.triple_bogey_over") }}
        </p>
      </div>
    </div>

    <div class="d-flex justify-content-end">
      <div class="box m-none">
        <p class="eagle"><span></span>{{ $t("text.common.eagle") }}</p>
        <p class="birdie"><span></span>{{ $t("text.common.birdie") }}</p>
        <p class="par"><span></span>{{ $t("text.common.par") }}</p>
        <p class="bogey"><span></span>{{ $t("text.common.bogey") }}</p>
        <p class="d-bogey"><span></span>{{ $t("text.common.double_bogey") }}</p>
        <p class="t-bogey-over">
          <span></span>{{ $t("text.common.triple_bogey_over") }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { myMixin } from "@/mixin/myMixin";
export default {
  name: "Scorecard",
  props: {
    scoreCardInfo: {
      type: [Array, Object],
      default: function () {
        return [
          {
            coursename: "",
            date: "",
            nickname: "",
            playtype: "",
            shopname: "",
          },
        ];
      },
    },
    scoreCardStats: {
      type: [Array, Object],
      default: function () {
        return [
          {
            maxdriver: 0,
            avgdriver: 0,
            shot: 0,
            mulligan: 0,
            avggreenhit: 0,
            farewayrate: 0,
            greenrate: 0,
            score: 0,
            base_par: 0,
          },
        ];
      },
    },
    scoreCardTotal: {
      // partotal1, partotal2, shottotal1, shottotal2, scoretotal1, scoretotal2, mulligantotal1, mulligantotal2, mulligantotal
      type: [Array, Object],
      default: function () {
        return [
          {
            partotal1: 0,
            partotal2: 0,
            shottotal1: 0,
            shottotal2: 0,
            scoretotal1: 0,
            scoretotal2: 0,
            mulligantotal1: 0,
            mulligantotal2: 0,
            mulligantotal: 0,
            score: 0,
            base_par: 0,
          },
        ];
      },
    },
    scoreCardHolePar: {
      type: [Array, Object],
      default: function () {
        return [
          { holeno: 0, par: 0 },
          { holeno: 0, par: 0 },
          { holeno: 0, par: 0 },
          { holeno: 0, par: 0 },
          { holeno: 0, par: 0 },
          { holeno: 0, par: 0 },
          { holeno: 0, par: 0 },
          { holeno: 0, par: 0 },
          { holeno: 0, par: 0 },
          { holeno: 0, par: 0 },
          { holeno: 0, par: 0 },
          { holeno: 0, par: 0 },
          { holeno: 0, par: 0 },
          { holeno: 0, par: 0 },
          { holeno: 0, par: 0 },
          { holeno: 0, par: 0 },
          { holeno: 0, par: 0 },
          { holeno: 0, par: 0 },
        ];
      },
    },
    scoreCardScore: {
      type: [Array, Object],
      default: function () {
        return [
          { shot: 0, score: 0, mulligan: 0 },
          { shot: 0, score: 0, mulligan: 0 },
          { shot: 0, score: 0, mulligan: 0 },
          { shot: 0, score: 0, mulligan: 0 },
          { shot: 0, score: 0, mulligan: 0 },
          { shot: 0, score: 0, mulligan: 0 },
          { shot: 0, score: 0, mulligan: 0 },
          { shot: 0, score: 0, mulligan: 0 },
          { shot: 0, score: 0, mulligan: 0 },
          { shot: 0, score: 0, mulligan: 0 },
          { shot: 0, score: 0, mulligan: 0 },
          { shot: 0, score: 0, mulligan: 0 },
          { shot: 0, score: 0, mulligan: 0 },
          { shot: 0, score: 0, mulligan: 0 },
          { shot: 0, score: 0, mulligan: 0 },
          { shot: 0, score: 0, mulligan: 0 },
          { shot: 0, score: 0, mulligan: 0 },
          { shot: 0, score: 0, mulligan: 0 },
        ];
      },
    },
    // subCourseListCnt: {
    //   type: Number,
    //   default: 0,
    // },
    subCourseList: {
      type: [Array, Object],
      default: function () {
        return [
          { subcourseseq: 0, subcourseno: 0, subcoursename: "OUT" },
          { subcourseseq: 0, subcourseno: 0, subcoursename: "IN" },
        ];
      },
    },
  },
  mixins: [myMixin],
};
</script>
