<template>
  <div>
    <location />

    <div class="p12_view page wrap-1200">
      <h3 class="subtitle">{{ shopinfo.shopname }}</h3>
      <GmapMap
        :center="{
          lat: parseFloat(shopinfo.maploc2),
          lng: parseFloat(shopinfo.maploc1),
        }"
        :zoom="15"
        map-type-id="roadmap"
        style="width: 100%; height: 300px"
      >
      </GmapMap>
      <div class="row g-0 border-top-dgray">
        <div class="col-lg-6 border-bottom">
          <div class="row g-0">
            <div class="col-4 d-table bg-gray">
              <label class="d-table-cell">{{
                $t("title.common.location")
              }}</label>
            </div>
            <div class="col d-table">
              <p class="d-table-cell text-break">{{ shopinfo.shopname }}</p>
            </div>
          </div>
        </div>
        <div class="col-lg-6 border-bottom">
          <div class="row g-0">
            <div class="col-4 d-table bg-gray">
              <label class="d-table-cell">{{
                $t("title.common.location_type")
              }}</label>
            </div>
            <div class="col d-table">
              <p class="d-table-cell text-break">
                {{
                  shopinfo.shoptype == 1
                    ? "Corporate-owned Shop"
                    : "Franchising Shop"
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row g-0">
        <div class="col-lg-6 border-bottom">
          <div class="row g-0">
            <div class="col-4 d-table bg-gray">
              <label class="d-table-cell">{{
                $t("title.common.address")
              }}</label>
            </div>
            <div class="col d-table">
              <p class="d-table-cell text-break">
                {{ shopinfo.address }} {{ shopinfo.city }}
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-6 border-bottom">
          <div class="row g-0">
            <div class="col-4 d-table bg-gray">
              <label class="d-table-cell">{{
                $t("title.common.region")
              }}</label>
            </div>
            <div class="col d-table">
              <p class="d-table-cell text-break">{{ shopinfo.area }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row g-0">
        <div class="col-lg-6 border-bottom">
          <div class="row g-0">
            <div class="col-4 d-table bg-gray">
              <label class="d-table-cell">{{
                $t("title.common.post_code")
              }}</label>
            </div>
            <div class="col d-table">
              <p class="d-table-cell text-break">{{ shopinfo.zipcode }}</p>
            </div>
          </div>
        </div>
        <div class="col-lg-6 border-bottom">
          <div class="row g-0">
            <div class="col-4 d-table bg-gray">
              <label class="d-table-cell">{{ $t("title.common.email") }}</label>
            </div>
            <div class="col d-table">
              <p class="d-table-cell text-break">
                <a
                  :href="'mailto:' + shopinfo.email"
                  target="_blank"
                  class="more"
                >
                  {{ shopinfo.email }}
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row g-0">
        <div class="col-lg-6 border-bottom">
          <div class="row g-0">
            <div class="col-4 d-table bg-gray">
              <label class="d-table-cell">{{ $t("title.common.phone") }}</label>
            </div>
            <div class="col d-table">
              <p class="d-table-cell text-break">{{ shopinfo.phone }}</p>
            </div>
          </div>
        </div>
        <div class="col-lg-6 border-bottom">
          <div class="row g-0">
            <div class="col-4 d-table bg-gray">
              <label class="d-table-cell">{{ $t("title.common.fax") }}</label>
            </div>
            <div class="col d-table">
              <p class="d-table-cell text-break">{{ shopinfo.fax }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row g-0">
        <div class="col-lg-6 border-bottom">
          <div class="row g-0">
            <div class="col-4 d-table bg-gray">
              <label class="d-table-cell">{{
                $t("title.common.homepage")
              }}</label>
            </div>
            <div class="col d-table">
              <p class="d-table-cell text-break">
                <a :href="shopinfo.website" target="_blank" class="more">
                  {{ shopinfo.website }}
                </a>
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-6 border-bottom">
          <div class="row g-0">
            <div class="col-4 d-table bg-gray">
              <label class="d-table-cell">{{
                $t("title.common.simulator_count")
              }}</label>
            </div>
            <div class="col d-table">
              <p class="d-table-cell text-break">
                {{ shopinfo.systemcnt | comma }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="btn-bottom-wrapper">
        <b-button
          size="xl"
          variant="outline-primary"
          aria-label=""
          @click="
            $router.push({
              name: 'FavoriteLocation',
              query: {
                searchtype: $route.query.searchtype,
                searchname: $route.query.searchname,
              },
            })
          "
          >{{ $t("button.common.list") }}</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import ax from "@/api/membership";
import "@/filter/common";

export default {
  name: "FavoriteLocationInfo",
  components: {
    location,
  },
  data() {
    return {
      shopno: this.$route.params.shopno,
      shopinfo: [],
    };
  },
  methods: {
    get_membershopinfo() {
      ax.get_membershopinfo(this.shopno, (flag, data) => {
        if (flag) {
          this.shopinfo = data.shopinfo;
        } else {
          alert(data);
        }
      });
    },
  },
  created() {
    this.get_membershopinfo();
  },
};
</script>
