var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('swiper',{staticClass:"swiper mySwiper",attrs:{"options":{
      slidesPerView: 'auto',
      freeMode: true,
      initialSlide: _vm.tab - 1,
    }}},[_c('swiper-slide',{class:{ active: _vm.tab == 1 }},[_c('div',{on:{"click":function($event){_vm.$router.push('/membership/myrecords/myrounds').catch(function () {})}}},[_vm._v(" "+_vm._s(_vm.$t("button.membership.my_rounds"))+" ")])]),_c('swiper-slide',{class:{ active: _vm.tab == 2 }},[_c('div',{on:{"click":function($event){_vm.$router.push('/membership/myrecords/mytournaments').catch(function () {})}}},[_vm._v(" "+_vm._s(_vm.$t("button.membership.my_tournaments"))+" ")])]),_c('swiper-slide',{class:{ active: _vm.tab == 3 }},[_c('div',{on:{"click":function($event){_vm.$router.push('/membership/myrecords/myevent').catch(function () {})}}},[_vm._v(" "+_vm._s(_vm.$t("button.membership.my_events"))+" ")])]),_c('swiper-slide',{class:{ active: _vm.tab == 4 }},[_c('div',{on:{"click":function($event){_vm.$router.push('/membership/myrecords/myswing').catch(function () {})}}},[_vm._v(" "+_vm._s(_vm.$t("button.membership.my_swings"))+" ")])])],1),_c('ul',{staticClass:"nav nav-pills wrap-1200",attrs:{"id":"pills-tab","role":"tablist"}},[_c('li',{staticClass:"nav-item col-3",attrs:{"role":"presentation"}},[_c('button',{staticClass:"w-100 nav-link",class:{
          active:
            _vm.$route.name === 'MyRounds' || _vm.$route.name === 'MyRoundsView',
        },attrs:{"id":"pills-1-tab","data-bs-toggle":"pill","data-bs-target":"#pills-1","type":"button","role":"tab","aria-controls":"pills-1","aria-selected":"true"},on:{"click":function($event){_vm.$router.push('/membership/myrecords/myrounds').catch(function () {})}}},[_vm._v(" "+_vm._s(_vm.$t("button.membership.my_rounds"))+" ")])]),_c('li',{staticClass:"nav-item col-3",attrs:{"role":"presentation"}},[_c('button',{staticClass:"w-100 nav-link",class:{
          active:
            _vm.$route.name === 'MyTournaments' ||
            _vm.$route.name === 'MyTournamentsView' ||
            _vm.$route.name === 'MyShopTournamentsView' ||
            _vm.$route.name === 'MyTournamentsAttend' ||
            _vm.$route.name === 'MyScorecard' ||
            _vm.$route.name === 'MyShopTournamentsRanking' ||
            _vm.$route.name === 'MyTournamentsRanking',
        },attrs:{"id":"pills-2-tab","data-bs-toggle":"pill","data-bs-target":"#pills-2","type":"button","role":"tab","aria-controls":"pills-2","aria-selected":"false"},on:{"click":function($event){_vm.$router.push('/membership/myrecords/mytournaments').catch(function () {})}}},[_vm._v(" "+_vm._s(_vm.$t("button.membership.my_tournaments"))+" ")])]),_c('li',{staticClass:"nav-item col-3",attrs:{"role":"presentation"}},[_c('button',{staticClass:"w-100 nav-link",class:{
          active:
            _vm.$route.name === 'MyEvent' ||
            _vm.$route.name === 'MyMiniGameRanking' ||
            _vm.$route.name === 'MyWinAMiniGame',
        },attrs:{"id":"pills-3-tab","data-bs-toggle":"pill","data-bs-target":"#pills-3","type":"button","role":"tab","aria-controls":"pills-3","aria-selected":"false"},on:{"click":function($event){_vm.$router.push('/membership/myrecords/myevent').catch(function () {})}}},[_vm._v(" "+_vm._s(_vm.$t("button.membership.my_events"))+" ")])]),_c('li',{staticClass:"nav-item col-3",attrs:{"role":"presentation"}},[_c('button',{staticClass:"w-100 nav-link",class:{
          active: _vm.$route.name === 'MySwing' || _vm.$route.name === 'MySwingView',
        },attrs:{"id":"pills-4-tab","data-bs-toggle":"pill","data-bs-target":"#pills-4","type":"button","role":"tab","aria-controls":"pills-4","aria-selected":"false"},on:{"click":function($event){_vm.$router.push('/membership/myrecords/myswing').catch(function () {})}}},[_vm._v(" "+_vm._s(_vm.$t("button.membership.my_swings"))+" ")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }